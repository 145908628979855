




































































































































import { Component, Prop, Vue, Mixins, Ref } from "vue-property-decorator";
import VModal from "@/components/VModal.vue";
import PrintCategoriesMixin from "@/mixins/http/PrintCategoriesMixin";
import IUser from "@/models/interfaces/IUser";
import { NoCache, RegisterHttp } from "@/utils/Decorators";
import PrintCategory, { PrintCategoryPayload } from "@/models/PrintCategory";
import { RequestData } from "@/store/types/RequestStore";
import { showToast } from "@/utils/Toast";
import PrintTechnologySelect from "@/components/PrintTechnology/Select.vue";
import ColorInput from "@/components/ColorInput.vue";
import FileUploader from "@/components/FileUploader.vue";

const updateTag = "update_user";
const createTag = "create_user";

@Component({
  components: {
    VModal,
    ColorInput,
    FileUploader,
    PrintTechnologySelect,
  },
})
export default class EditPrintCategory extends Mixins(PrintCategoriesMixin) {
  item: PrintCategoryPayload = null;
  @Ref() readonly modal!: VModal;
  content = "";
  isNew = false;

  file: any = null;

  @RegisterHttp(createTag) createRequest?: RequestData;
  @RegisterHttp(updateTag) updateRequest?: RequestData;

  canceled!: any | null;

  get valid() {
    if (!this.item) {
      return false;
    }
    return (
      this.item.color &&
      this.item.columns &&
      this.item.rows &&
      this.item.name &&
      this.item.height &&
      this.item.width &&
      this.item.imageWidth &&
      this.item.imageHeight &&
      this.item.printTechnologyId
    );
  }

  get qrCodeCutUrl() {
    return this.file?.url || this.item?.qrCodeCutUrl;
  }

  get color() {
    return this.item.color;
  }

  set color(value) {
    this.item.color = value[0];
  }

  title() {
    return `${this.isNew ? "Aggiungi" : "Modifica"} categoria di stampa`;
  }

  get hasContent(): boolean {
    return this.$slots.default != null;
  }

  protected onCancel() {
    if (this.canceled) {
      this.canceled();
    }

    this.canceled = null;
    this.modal.hide();
  }

  protected onConfirm() {
    if (this.valid) {
      this.$emit("confirmed", this.item);
      this.modal.hide();
    }
  }

  protected onHidden() {
    this.reset();
    if (this.canceled) {
      this.canceled();
    }
  }

  edit(item: PrintCategory) {
    this.isNew = false;
    this.modal.show();
    const printTechnologyId = item.printTechnology.id;
    this.item = Object.assign({ printTechnologyId: printTechnologyId }, item);
  }

  reset() {
    this.item = null;
  }

  create(sourceId: string) {
    this.isNew = true;
    this.modal.show();
    this.item = new PrintCategoryPayload();
    this.item.sourceId = sourceId;
  }

  get loading() {
    return this.createRequest?.loading || this.updateRequest?.loading;
  }

  async save() {
    const formData = new FormData();



    for(let key of Object.keys(this.item)) {
      formData.append(key, (this.item as any)[key]);
    }

    if(this.file) {
      formData.append("qrCodeCut", this.file.file);
    }


    try {
      const resp = this.isNew
        ? await this.createPrintCategory(formData, createTag)
        : await this.updatePrintCategory(this.item.id,formData, updateTag);
      const emitTag = this.isNew ? "created" : "updated";
      this.$emit(emitTag, resp);
      this.modal.hide();
      showToast("Categoria di stampa Salvata!");
    } catch (err) {
      showToast("Salvataggio fallito!", {
        type: "error",
      });
    }
  }
}
