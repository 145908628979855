







































































































import { Order, Source } from "@/models";
import ConfirmModal from "@/components/ConfirmModal.vue";
import Search from "@/components/VEntrySearch.vue";

import EditModal from "@/components/PrintCategory/Edit.vue";
import {
  Vue,
  Component,
  Mixins,
  Watch,
  Ref
} from "vue-property-decorator";
import { Getter, Action, namespace } from "vuex-class";
import PrintCategoriesMixin from "@/mixins/http/PrintCategoriesMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import StoreSubscriber from "@/store/StoreSubscriber";
import DataTable from "@monade/vue-components/src/components/DataTable.vue";
import RoundThumb from "@/components/RoundThumb.vue";
import VPageContentSticky from "@/components/VPageContentSticky.vue";
import { PrintCategory } from "@/models";
import VColor from "@/components/VColor.vue";
import EmptyView from "@/components/EmptyView.vue";
import { easync } from "@/utils/http";
import { showToast } from "@/utils/Toast";
import { sourceStore } from "@/store/typed";

const getTag = "get_warehouse_items";
const destroyTag = "destroy_warehouse_item";

@Component({
  components: {
    EditModal,
    EmptyView,
    VColor,
    Search,
    VPageContentSticky,
    DataTable,
    ConfirmModal,
    RoundThumb
  }
})
export default class PrintCategoryList extends Mixins(PrintCategoriesMixin) {
  @RegisterHttp(getTag) getRequest: RequestData;
  @RegisterHttp(destroyTag) destroyRequest: RequestData;
  @Ref() editModal: HTMLDivElement;
  @Ref() confirmModal: HTMLDivElement;

  items: PrintCategory[] = [];
  page = 1;

  columns = [
    { name: "name", label: "Nome" },
    { name: "document_layout", label: "Layout" },
    { name: "document_size", label: "Dimensione documento" },
    { name: "image_size", label: "Dimensione immagine" },
    { name: "productCount", label: "Prodotti" },
    { name: "actions", label: "" }
  ];

  search: string | null = null;

  created() {
    this.subscribe();
  }

  onCreated(item: PrintCategory) {
    this.items.unshift(item);
  }

  onUpdated(item: PrintCategory) {
    const index = this.items.findIndex(o => item.id === o.id);
    Vue.set(this.items, index, item);
  }

  async searchItems(name: string) {
    if (name.trim().length > 0) {
      this.search = name;
      const items = await this.getPrintCategories(this.payload, getTag);
      return items;
    }
  }

  onSearchCanceled() {
    this.page = 1;
    this.search = null;
    this.loadItems();
  }

  onSearched(items: {items: PrintCategory[]}) {
    this.page = 1;
    this.items = items.items;
  }

  onDeleted(id: string) {
    this.items = this.items.filter(o => id !== o.id);
  }

  openCreateModal() {
    (this.editModal as any).create(sourceStore.currentSource.id);
  }

  openDetail(item: PrintCategory){
    this.$router.push({name: 'print_category_show', params:{id: item.id}})
  }

  openCreatePage(){
    this.$router.push({name: 'print_category_show', params:{id: 'new'}})
  }

  openEditModal(item: PrintCategory) {
    (this.editModal as any).edit(item);
  }

  async openConfirmModal(item: PrintCategory) {
    const [data, errors] = await easync(
      (this.confirmModal as any).confirm("Sei sicuro di eliminare questa categoria di stampa?")
    );

    if (errors) {
      showToast("Si è verificato un errore", {type: "error"});
      return;
    }

    if (data) {
      this.destroyItem(item.id);
    }
  }

  @Watch("page")
  onPageChanged(value: any, oldValue: any) {
    if (value == 1 && oldValue) {
      this.items = [];
    }
    this.loadItems();
  }

  async destroyItem(id: string) {
    const [data, errors] = await easync(this.destroyPrintCategory(id, destroyTag));
    if (errors) {
      showToast("Si è verificato un errore", { type: "error" });
      return;
    }

    this.onDeleted(id);
  }

  mounted() {
    if (sourceStore.currentSource) {
      this.loadItems();
    }
  }

  subscribe() {
    StoreSubscriber.subscribe("source/setCurrentSource", this.onSourceSet);
  }

  onSourceSet(source: Source | undefined) {
    if (source) {
      this.loadItems();
    }
  }

  nextPage() {
    this.page += 1;
  }

  get payload() {
    const data = {
      per: 20,
      include: ["name"],
      page: this.page,
      filter: {
        source: sourceStore.currentSource?.id,
        term: this.search
      }
    };

    return data;
  }

  async loadItems() {
    const [data, errors] = await easync(this.getPrintCategories(this.payload, getTag));
    if (errors) {
      if (this.page > 1) {
        this.page -= 1;
      }
      return;
    }

    if (this.page === 1) {
      this.items = [];
    }
    this.items.push(...data);
  }

  get loading() {
    return this.getRequest?.loading;
  }

  get meta() {
    return this.getRequest?.meta;
  }
}
